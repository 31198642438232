import { ClickStream } from "../clickstream";
import $ from "jquery";
$(function () {
    var THIRTY_SECONDS = 30000;
    setTimeout(function () {
        ClickStream.postClickStreamWithTrackingArguments({
            pageAction: "30s",
            servletName: ClickStream.getServlet()
        });
    }, THIRTY_SECONDS);
});
