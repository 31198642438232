import { SubfolderUrlPrefix } from "../ts/subfolderUrlPrefix";
var subfolderUrlPrefix = new SubfolderUrlPrefix(document);
if (window.ActiveXObject) {
    var axOpen_1 = ActiveXObject.prototype.open;
    ActiveXObject.prototype.open = function () {
        if (arguments.length > 1) {
            arguments[1] = subfolderUrlPrefix.apply(arguments[1]);
        }
        axOpen_1.apply(this, arguments);
    };
}
if (window.XMLHttpRequest) {
    var xhrOpen_1 = XMLHttpRequest.prototype.open;
    XMLHttpRequest.prototype.open = function () {
        if (arguments.length > 1) {
            arguments[1] = subfolderUrlPrefix.apply(arguments[1]);
        }
        xhrOpen_1.apply(this, arguments);
    };
}
