import { fromEvent as observableFromEvent } from "rxjs";
import { ClickStream } from "./clickstream";
import { debounceTime, first } from "rxjs/operators";
import $ from "./common/jquery";
import { HelpCenterLoader } from "./helpCenterLoader";
import { ViewportBreakpoint } from "./common/utils/viewportBreakpoints";
var JS_BODY_NO_SCROLL_CLASS = "js-body-no-scroll";
var UserMenuDrawer = (function () {
    function UserMenuDrawer() {
        var _this = this;
        this.$avatarButton = $('.global-nav-header-avatar');
        this.$drawerOverlay = $('.global-nav-mobile-user-menu-drawer-overlay');
        this.$drawer = $('.global-nav-mobile-user-menu-drawer');
        this.$closeButton = $('.global-nav-mobile-user-menu-close-button');
        this.$helpButton = $('#helpMenuButtonMobile');
        if (this.$drawer.length === 0) {
            return;
        }
        if (window.innerWidth < ViewportBreakpoint.BOOTSTRAP_LG) {
            this.init();
        }
        else {
            observableFromEvent(window, "resize")
                .pipe(debounceTime(200))
                .subscribe(function () {
                if (window.innerWidth < ViewportBreakpoint.BOOTSTRAP_LG) {
                    _this.init();
                }
            });
        }
    }
    UserMenuDrawer.prototype.init = function () {
        this.bindAvatarButton();
        this.bindCloseBehaviors();
        this.bindAnimations();
        this.bindOpenHelpMenu();
    };
    UserMenuDrawer.prototype.bindAvatarButton = function () {
        var _this = this;
        this.fireProfileAvatarVisibilityTracking();
        observableFromEvent(this.$avatarButton, "click")
            .subscribe(function () {
            document.body.classList.add(JS_BODY_NO_SCROLL_CLASS);
            _this.$drawerOverlay.removeClass('d-none');
            _this.$drawerOverlay.addClass('js-open-animation');
        });
        observableFromEvent(this.$avatarButton, "click")
            .pipe(first())
            .subscribe(function () { return _this.fireSideDrawerItemsTracking(); });
    };
    UserMenuDrawer.prototype.bindCloseBehaviors = function () {
        var _this = this;
        observableFromEvent(this.$drawerOverlay, "click")
            .subscribe(function (evt) {
            var $eventTarget = $(evt.target);
            var $closestDrawerAncestor = $eventTarget.closest(".global-nav-mobile-user-menu-drawer");
            var $closestModalToggle = $eventTarget.closest("[data-toggle='modal']");
            if ($closestDrawerAncestor.length === 0 || $closestModalToggle.length > 0) {
                _this.triggerClose();
            }
        });
        observableFromEvent(this.$closeButton, "click")
            .subscribe(function () {
            _this.triggerClose();
        });
    };
    UserMenuDrawer.prototype.triggerClose = function () {
        document.body.classList.remove(JS_BODY_NO_SCROLL_CLASS);
        this.$drawerOverlay.addClass('js-close-animation');
    };
    UserMenuDrawer.prototype.bindAnimations = function () {
        var _this = this;
        observableFromEvent(this.$drawerOverlay, "animationend")
            .subscribe(function (evt) {
            var animationName = evt.originalEvent.animationName;
            if (animationName === 'drawerSlideIn') {
                var classes = evt.currentTarget.classList;
                if (classes.contains('js-open')) {
                    _this.$drawerOverlay.removeClass('js-close-animation');
                    _this.$drawerOverlay.removeClass('js-open');
                    _this.$drawerOverlay.addClass('d-none');
                }
                else {
                    _this.$drawerOverlay.removeClass('js-open-animation');
                    _this.$drawerOverlay.removeClass('js-close-animation');
                    _this.$drawerOverlay.addClass('js-open');
                }
            }
        });
    };
    UserMenuDrawer.prototype.fireSideDrawerItemsTracking = function () {
        var sideDrawerSimpleLinkItems = $('.global-nav-mobile-user-menu-drawer-simple-link').get();
        var sideDrawerCurrencyItem = $('.global-nav-mobile-user-menu-drawer-currency-button').get();
        var sideDrawerLanguageItem = $('.global-nav-mobile-user-menu-drawer-language-button').get();
        var allSideDrawerItems = sideDrawerSimpleLinkItems.concat(sideDrawerCurrencyItem, sideDrawerLanguageItem);
        allSideDrawerItems.forEach(function (element) {
            ClickStream.postClickStreamWithTrackingArguments({
                servletName: ClickStream.getServlet(),
                pageAction: element.getAttribute("data-action-tag")
                    .replace("click_", "view_"),
            });
        });
    };
    ;
    UserMenuDrawer.prototype.fireProfileAvatarVisibilityTracking = function () {
        this.$avatarButton.get().forEach(function () {
            ClickStream.postClickStreamWithTrackingArguments({
                servletName: ClickStream.getServlet(),
                pageAction: "view_header_profile_menu",
            });
        });
    };
    ;
    UserMenuDrawer.prototype.bindOpenHelpMenu = function () {
        var _this = this;
        observableFromEvent(this.$helpButton, "click")
            .subscribe(function (event) {
            _this.triggerClose();
            HelpCenterLoader.load(window, event)
                .then(function () { return HelpCenterLoader.activate(window, HelpCenterLoader.HC_HELP_SHELL); });
        });
    };
    return UserMenuDrawer;
}());
export { UserMenuDrawer };
