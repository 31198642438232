import $ from "../ts/common/jquery";
import { ClickStream } from "../ts/clickstream";
var GlobalNavDesktop = (function () {
    function GlobalNavDesktop() {
        this.GLOBAL_NAV_CATEGORY_MENU_CLASS = 'js-global-nav-category-menu';
        this.DISPLAY_NONE_CLASS = 'd-none';
        this.TOP_MENU_ITEM_BLACK_BORDER_CLASS = 'js-global-nav-desktop-top-menu-container-item-black-border-bottom';
        this.GLOBAL_NAV_CAT_MENU_ITEM_SELECTED = 'js-global-nav-category-menu-item-selected';
        this.activeL1 = null;
        this.activeL2 = null;
        this.activeL3 = null;
        this.l2TrackingEventsSent = new Set();
    }
    GlobalNavDesktop.prototype.init = function () {
        if (!this.getGlobalNavDesktopContainer()) {
            return;
        }
        this.setupL1();
        this.setupL2();
    };
    GlobalNavDesktop.prototype.l1GetCategoryMenuContainer = function (l1Index) {
        return document.getElementById("global-nav-category-menu-" + l1Index);
    };
    GlobalNavDesktop.prototype.tearDownActiveL1 = function (evt) {
        if (this.activeL1 === null) {
            return;
        }
        var categoryMenuContainer = this.l1GetCategoryMenuContainer(this.getL1Index(this.activeL1));
        if (evt && categoryMenuContainer.contains(evt.relatedTarget)) {
            return;
        }
        categoryMenuContainer.classList.remove(this.GLOBAL_NAV_CATEGORY_MENU_CLASS);
        categoryMenuContainer.classList.add(this.DISPLAY_NONE_CLASS);
        this.activeL1.classList.remove(this.TOP_MENU_ITEM_BLACK_BORDER_CLASS);
        this.activeL1 = null;
        this.tearDownActiveL2();
        this.tearDownActiveL3();
    };
    GlobalNavDesktop.prototype.setupL1 = function () {
        var _this = this;
        this.setupClickAwayListener(this.getGlobalNavDesktopContainer());
        $('.global-nav-desktop-top-menu-container-item')
            .each(function (index, element) {
            var listener = function (evt) {
                var l1Idx = _this.getL1Index(element);
                var categoryMenuContainer = _this.l1GetCategoryMenuContainer(l1Idx);
                switch (evt.type) {
                    case 'mouseenter':
                    case 'touchend':
                        categoryMenuContainer.classList.add(_this.GLOBAL_NAV_CATEGORY_MENU_CLASS);
                        categoryMenuContainer.classList.remove(_this.DISPLAY_NONE_CLASS);
                        element.classList.add(_this.TOP_MENU_ITEM_BLACK_BORDER_CLASS);
                        if (_this.activeL1 !== element) {
                            _this.tearDownActiveL1();
                        }
                        _this.activeL1 = element;
                        document
                            .getElementById("global-nav-category-menu-item-" + l1Idx + "-0")
                            .dispatchEvent(new Event('mouseenter'));
                        break;
                    case 'mouseleave':
                        _this.tearDownActiveL1(evt);
                        break;
                }
            };
            _this.addWrappedEventsListener(element, listener);
        });
    };
    GlobalNavDesktop.prototype.setupL2 = function () {
        var _this = this;
        this.setupL2LeftSide();
        $('.global-nav-category-menu')
            .each(function (index, element) {
            return _this.addWrappedEventsListener(element, function (evt) {
                switch (evt.type) {
                    case 'mouseleave':
                        _this.tearDownActiveL1();
                        break;
                }
            });
        });
    };
    GlobalNavDesktop.prototype.scheduleL2Tracking = function (l2Element) {
        var _this = this;
        if (this.l2TrackingEventsSent.has(l2Element)) {
            return;
        }
        setTimeout(function () {
            if ((l2Element !== _this.activeL2) || (_this.l2TrackingEventsSent.has(l2Element))) {
                return;
            }
            _this.l2TrackingEventsSent.add(l2Element);
            var level1Id = l2Element.getAttribute('data-l1-tracking-id');
            var level2Id = l2Element.getAttribute('data-l2-tracking-id');
            ClickStream
                .postClickStreamWithTrackingArguments({
                pageAction: 'view_header_nav_links',
                pageProperties: {
                    level1Id: level1Id, level2Id: level2Id
                },
                servletName: ClickStream.getServlet()
            });
        }, 500);
    };
    ;
    GlobalNavDesktop.prototype.tearDownActiveL2 = function () {
        if (this.activeL2) {
            this.activeL2.classList.remove(this.GLOBAL_NAV_CAT_MENU_ITEM_SELECTED);
        }
        this.activeL2 = null;
    };
    GlobalNavDesktop.prototype.setupL2LeftSide = function () {
        var _this = this;
        $('.global-nav-category-menu-item')
            .each(function (_, element) {
            return _this.addWrappedEventsListener(element, function (evt) {
                var l3Element = document
                    .getElementById("global-nav-links-" + _this.getL1Index(element) + "-" + _this.getL2Index(element));
                switch (evt.type) {
                    case 'mouseenter':
                    case 'touchend':
                        _this.scheduleL2Tracking(element);
                        element.classList.add(_this.GLOBAL_NAV_CAT_MENU_ITEM_SELECTED);
                        l3Element.classList.remove(_this.DISPLAY_NONE_CLASS);
                        if (_this.activeL2 !== element) {
                            _this.tearDownActiveL2();
                        }
                        if (_this.activeL3 !== l3Element) {
                            _this.tearDownActiveL3();
                        }
                        _this.activeL2 = element;
                        _this.activeL3 = l3Element;
                        break;
                }
            });
        });
    };
    GlobalNavDesktop.prototype.tearDownActiveL3 = function () {
        if (this.activeL3) {
            this.activeL3.classList.add(this.DISPLAY_NONE_CLASS);
        }
        this.activeL3 = null;
    };
    GlobalNavDesktop.prototype.getGlobalNavDesktopContainer = function () {
        return document.getElementById('global-nav-desktop-container');
    };
    GlobalNavDesktop.prototype.getL1Index = function (element) {
        return element.getAttribute('data-l1-idx');
    };
    GlobalNavDesktop.prototype.getL2Index = function (element) {
        return element.getAttribute('data-l2-idx');
    };
    GlobalNavDesktop.prototype.setupClickAwayListener = function (globalNavBar) {
        var _this = this;
        document.addEventListener('touchend', function (touchEvent) {
            if (_this.activeL1 && (globalNavBar.contains(touchEvent.target) === false)) {
                _this.activeL1.dispatchEvent(new Event('mouseleave'));
            }
        });
    };
    GlobalNavDesktop.prototype.addWrappedEventsListener = function (elm, listener) {
        var listenerWrapper = function (evt) {
            evt.stopPropagation();
            listener(evt);
        };
        [
            'mouseenter', 'touchend',
            'mouseleave'
        ].forEach(function (type) { return elm.addEventListener(type, listenerWrapper); });
    };
    return GlobalNavDesktop;
}());
export { GlobalNavDesktop };
